import React from "react";
import styles from "./OurProducts.module.scss";
import BackgroundImage from "../elements/BackgroundImage";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import Button from "../elements/Button";
import FadeUp from "../effects/FadeUp";
import Row from "../layout/Row";
import Container from "../layout/Container";
import Section from "../layout/Section";

const OurProducts = ({title, intro, items, theme}) => {

    const itemsElements = items && items.length ? items.map((item,i) => {

        const productColor = item.theme && item.theme[0] ? item.theme[0].color : null;
        let themeForItem = item.style === "brightscan" ? "custom-light" : "custom-image";
        if(!productColor) themeForItem = item.style === "brightscan" ? "brightscan-light" : `torus-image`;
        const useGradientBg = item.style === "brightscan";

        const aspectRatioImage = item.image && item.image[0] ? item.image[0].width / item.image[0].height : 1;
        const aspectRatioImageMobile = item.mobileImage && item.mobileImage[0] ? item.mobileImage[0].width / item.mobileImage[0].height : aspectRatioImage;


        const featuresElements = item.features && item.features.length ? item.features.map((feature,j) => {
            return (
                <FadeUp as="li" delay={`${j * 0.15}s`} disableDelayMobile={true} key={j}>
                    {feature.icon && feature.icon.length ? <span dangerouslySetInnerHTML={{__html: feature.icon[0].contents}} /> : null}
                    {feature.feature ? <p>{feature.feature}</p> : null}
                </FadeUp>
            );
        }) : null;


        return (
            <Section className={`${styles.ourProductsItem} ${useGradientBg ? styles.ourProductsItemGradientBg : ""}`} theme={themeForItem} productColor={productColor} key={i}>
                {item.backgroundImage && item.style === "torus" ? <BackgroundImage image={item.backgroundImage[0]} /> : null}
                <Container>
                    <div>
                        <FadeUp className={`${styles.ourProductsItem__details} ${item.style === "torus" ? styles.ourProductsItem__detailsTorus : null}`}>
                            <div className={styles.ourProductsItem__details__content}>
                                <div>
                                    {item.logo && item.logo.length ?
                                        <GatsbyImage
                                            fluid={generateFluid(
                                                item.logo[0],
                                                "(min-width: 1680px) 503px, (min-width: 600px) 29vw, 100vw",
                                                "",
                                                true
                                            )}
                                            className={styles.ourProductsItem__details__content__logo}
                                            style={{"--aspect-ratio": `${item.logo[0].width / item.logo[0].height}`}}
                                            alt={item.productName}
                                        />
                                    : null}
                                    {item.productTitle ? <h3>{item.productTitle}</h3> : null}
                                    {item.productContent ?
                                        <div className={styles.ourProductsItem__details__content__description}>
                                            <span dangerouslySetInnerHTML={{__html: item.productContent}} />
                                        </div>
                                    : null}
                                    {item.primaryCta && item.primaryCta.url ?
                                        <Button url={item.primaryCta.url} target={item.primaryCta.target} external={item.primaryCta.target === "_blank"} text={item.primaryCta.text} filledStyle={true} altColors={true} />
                                    : null}
                                    {item.secondaryCta && item.secondaryCta.url ?
                                        <Button url={item.secondaryCta.url} target={item.secondaryCta.target} external={item.secondaryCta.target === "_blank"} text={item.secondaryCta.text} />
                                    : null}
                                </div>
                            </div>
                            <div className={styles.ourProductsItem__details__imageContainer}>
                                {item.image && item.image.length ?
                                    <GatsbyImage
                                        fluid={item.mobileImage && item.mobileImage.length ? 
                                            [
                                                generateFluid(item.mobileImage[0], "(min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw"),
                                                generateFluid(item.image[0], "(min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw", "(min-width: 1024px)")
                                            ] :
                                            [
                                                generateFluid(item.image[0], "(min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw")
                                            ]
                                        }
                                        className={styles.ourProductsItem__details__image}
                                        style={{"--aspect-ratio": aspectRatioImage, "--aspect-ratio-mobile": aspectRatioImageMobile}}
                                    />
                                : null}
                            </div>
                        </FadeUp>
                        <div className={styles.ourProductsItem__features}>
                            {featuresElements ?
                                <ul>
                                    {featuresElements}
                                </ul>
                            : null}
                        </div>
                    </div>
                </Container>
            </Section>
        );
    }) : null;

    return title || intro || itemsElements ? (
        <>
            <Section className={`${styles.ourProducts} ${!title && !intro ? styles.ourProductsNoPaddingTop : ""}`} theme={theme}>
                {title || intro ?
                    <Container>
                        <Row>
                            <div className={styles.ourProducts__intro}>
                                {title ? <h6>{title}</h6> : null}
                                {intro ? <span dangerouslySetInnerHTML={{__html: intro}} /> : null}
                            </div>
                        </Row>
                    </Container>
                : null}
                {itemsElements}
            </Section>
        </>
    ) : null;
};

export default OurProducts;