import React from "react"
import Hero from "../Hero/Hero"

const HeroMain = ({
    title,
    description,
    primaryCta,
    secondaryCta,
    animation,
    theme,
    howItWorksRef
}) => {
    return (
        <Hero
            title={title}
            description={description}
            primaryCta={primaryCta}
            secondaryCta={secondaryCta}
            animation={animation}
            type="main"
            theme={theme}
            howItWorksRef={howItWorksRef}
        />
    )
}

export default HeroMain
