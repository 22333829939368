import React, { useRef, useState, forwardRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./HowItWorks.module.scss"
import { useWebGLContext } from "../contexts/WebGLContext"
import Row from "../layout/Row"
import Container from "../layout/Container"
import Section from "../layout/Section"
import useMergedRef from "@react-hook/merged-ref"
import { clamp } from "../../util/math"
import GatsbyImage from "gatsby-image"

const HowItWorks = forwardRef(({ items, animation, theme }, ref) => {
    const { allFile } = useStaticQuery(
        graphql`
            {
                allFile(filter: { name: { regex: "/(map)|(virus-scan)/" } }) {
                    edges {
                        node {
                            publicURL
                            name
                            childImageSharp {
                                fluid(maxWidth: 3072) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    let images = {}
    allFile.edges.length &&
        allFile.edges.forEach(edge => {
            const { node: { publicURL, name, childImageSharp } = {} } =
                edge || {}

            images[name] = {
                childImageSharpFluid: childImageSharp && childImageSharp.fluid,
                publicURL,
            }
        })

    const [tileVal, setTileVal] = useState(0)

    const tilesRef = useRef()
    const webglContext = useWebGLContext()
    const tilesTracker = useRef()

    const sectionRefs = useMergedRef(tilesRef, ref)

    useEffect(() => {
        const { data, setData } = webglContext
        const tiles =
            items && items.length
                ? Array.from(tilesRef.current.querySelectorAll(".webgl_tile"))
                : []

        const trackers =
            items && items.length
                ? Array.from(
                      tilesRef.current.querySelectorAll(".webgl_tracker")
                  )
                : []

        data.tiles = tiles
        data.trackers = trackers

        setData({ ...data })
    }, [tilesRef])

    useEffect(() => {
        const tiles = tilesTracker.current

        const scroll = () => {
            const { top } = tiles ? tiles.getBoundingClientRect() : {}
            const { innerHeight: wh } = window
            const val = Math.floor(
                clamp(((top - wh / 2) / (wh * 4)) * -2, 0, 1)
            )

            setTileVal(val)

            // console.log(val)
        }

        window.addEventListener("scroll", scroll)

        return () => {
            window.removeEventListener("scroll", scroll)
        }
    }, [tileVal])

    const tiles =
        items && items.length
            ? items.slice(0, 2).map((item, i) => {
                  const opacity = i === tileVal ? 1 : 0
                  const transform = `translateY(${(i - tileVal) * 5}%)`
                  const transitionDelay = i === tileVal ? 0.4 : 0

                  const transition = `opacity  0.5s ${transitionDelay}s, transform  0.5s ${transitionDelay}s`

                  const getImageData = i => {
                      switch (i) {
                          case 0:
                              return images["map"]
                          default:
                              return images["virus-scan"]
                      }
                  }

                  const imageData = getImageData(i)

                  const itemInner = (
                      <Row verticalCenter={true}>
                          <div
                              style={{ opacity, transform, transition }}
                              className={`${styles.howItWorks__tiles__tile__content}`}
                          >
                              {item.tileTitle ? (
                                  <h3 className="masthead-h3">
                                      {item.tileTitle}
                                  </h3>
                              ) : null}
                              <div>
                                  <span
                                      dangerouslySetInnerHTML={{
                                          __html: item.tileContent,
                                      }}
                                  />
                              </div>
                          </div>
                          <div
                              className={`${styles.howItWorks__tiles__tile__animationWrapper}`}
                          >
                              <div
                                  className={
                                      styles.howItWorks__tiles__tile__animation +
                                      " webgl_tile"
                                  }
                              >
                                  {imageData.childImageSharpFluid ? (
                                      <GatsbyImage
                                          fluid={imageData.childImageSharpFluid}
                                          alt={item.tileTitle}
                                      />
                                  ) : (
                                      <img
                                          src={imageData.publicURL}
                                          alt={item.tileTitle}
                                      />
                                  )}
                              </div>
                          </div>
                      </Row>
                  )

                  return (
                      <div
                          key={i}
                          className={
                              styles.howItWorks__tiles__tile +
                              " " +
                              styles.sticky
                          }
                      >
                          {itemInner}
                      </div>
                  )
              })
            : null

    return tiles && tiles.length ? (
        <Section
            className={`${styles.howItWorks} ${
                animation ? styles.howItWorksAnimation : ""
            }`}
            theme={theme}
            ref={sectionRefs}
        >
            <Container extended={true}>
                <Row>
                    <div
                        className={
                            styles.howItWorks__tiles + " " + styles.stickyParent
                        }
                    >
                        <div className={styles.trackers} ref={tilesTracker}>
                            <div
                                className={styles.tracker + " webgl_tracker "}
                            ></div>
                            <div
                                className={styles.tracker + " webgl_tracker "}
                            ></div>
                        </div>
                        {tiles ? tiles : null}
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null
})

export default HowItWorks
