import React, { useEffect, useState } from "react";
import getMediumPosts from "../../util/getMediumPosts";
import styles from "./BlogPosts.module.scss";
import Button from "../elements/Button";
import External from "../../svgs/External";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const BlogPosts = ({theme}) => {
  const [mediumPosts, setMediumPosts] = useState(null);
  useEffect( () => {
    (async () => {
      let posts = await getMediumPosts();
      posts.items = posts.items.slice(0,3);
      setMediumPosts(posts);
    })()
  }, []);

  const postsElements = mediumPosts && mediumPosts.items && mediumPosts.items.length ? mediumPosts.items.map((post,i) => {
    const dateObj = new Date(post.pubDate.replace(/-/g, "/"));
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const formattedDate = `${dateObj.getFullYear()}-${month}-${day}`

    return post.link && (
      <div className={styles.blogPosts__body__itemsContainer__item} key={i}>
          <FadeUp
            delay={`${i * 0.15}s`}
            disableDelayMobile={true}
            className={styles.blogPosts__body__itemsContainer__item__inner}
          >
              {post.thumbnail ?
                <div className={styles.imageContainer}>
                  <a href={post.link} target="_blank" rel="nofollow noreferrer">
                    <img src={post.thumbnail} alt={post.title} />
                  </a>
                </div>
              : null}
              <div className={styles.content}>
                <div className={styles.metaDetails}>
                  <div>
                    <p className="meta"><span className={styles.author}>{post.author}</span></p>
                  </div>
                  <div>
                    <p className="meta">Posted <span className={styles.date}>{formattedDate}</span></p>
                  </div>
                </div>
                <div className={styles.title}>
                  {post.title &&
                    <h5>
                      <a href={post.link} target="_blank" rel="nofollow noreferrer">
                        {post.title}
                      </a>
                    </h5>
                  }
                </div>
                <Button
                  url={post.link}
                  text="Read on Medium"
                  simpleStyle={true}
                  external={true}
                  target="_blank"
                  buttonClassName={styles.button}
                />
              </div>
          </FadeUp>
      </div>
    );
  }) : <p>Loading...</p>;

  return (
    <Section className={styles.blogPosts} theme={theme}>
        <Container>
            <Row>
                <div className={styles.blogPosts__intro}>
                    <h4>From the blog</h4>
                    <p>
                      <a href="https://medium.com/openavn" target="_blank" rel="nofollow noreferrer">
                        View all posts on <strong>Medium</strong><span className={styles.linkSvg}>&nbsp;<External /></span>
                      </a>
                    </p>
                </div>
            </Row>
            <Row>
                <div className={styles.blogPosts__body}>
                  <div className={styles.blogPosts__body__itemsContainer}>
                    {postsElements}
                  </div>
                </div>
            </Row>
        </Container>
    </Section>
  );
}

export default BlogPosts;