const feedUrl = "https://medium.com/feed/openavn";
const baseUrl = " https://api.rss2json.com/v1/api.json"
export default async function getMediumPosts() {
  let cached = sessionStorage.getItem('feedUrl');
  if(cached) {
    let data = JSON.parse(cached);
    if(Date.now() - data.time < 1000* 60*60) {
      return data.data;
    }
  }
  let apiKey = process.env.GATSBY_RSS_TO_JSON_API_KEY;
  let queryUrl = `${baseUrl}?rss_url=${encodeURIComponent(feedUrl)}&api_key=${apiKey}`;
  let response = await fetch(queryUrl);
  let json = await response.json();
  sessionStorage.setItem('feedUrl', JSON.stringify({time: Date.now(), data: json}));
  return json;
}