import React, {Fragment} from "react";
import { useStaticQuery, graphql } from "gatsby";
import styles from "./Testimonials.module.scss";
import Slider from "../Slider";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import BackgroundImage from "../elements/BackgroundImage";
import QuoteMark from "../../svgs/QuoteMark";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const Testimonials = ({details, theme}) => {

    const {allFile} = useStaticQuery(
        graphql`
            {
                allFile(filter: {name: {eq: "testimonials-bg"}}) {
                    edges {
                        node {
                            childImageSharp {
                                fluid(maxWidth: 2560) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const imageData = allFile.edges[0].node.childImageSharp.fluid;

    const publications = details && details.publications && details.publications.length ? details.publications.map((item,i) => {
        return (
            item.logo && item.logo.length ?
                <GatsbyImage
                    key={i}
                    fluid={generateFluid(
                        item.logo[0],
                        "(min-width: 600px) 300px, 50vw",
                        "",
                        true
                    )}
                    className={styles.publications__image}
                    style={{"--aspect-ratio": `${item.logo[0].width / item.logo[0].height}`}}
                    alt={item.publicationName}
                />
            : null
        );
    }) : null;

    const testimonialsElements = details && details.testimonials && details.testimonials.length ? details.testimonials.map((item,i) => {
        return (
            <Fragment key={i}>
                <div className={styles.quote}>
                    <p><QuoteMark /><span dangerouslySetInnerHTML={{__html: item.testimonialContent}} /><QuoteMark /></p>
                </div>
                <div className={styles.attribution}>
                    {item.portrait ? <GatsbyImage fluid={generateFluid(item.portrait[0], "(min-width: 600px) 54px, 45px")} className={styles.attributionImage} /> : null}
                    <div className={styles.attribution__details}>
                        <div>
                            <p className="bold">{item.testimonialName}</p>
                        </div>
                        <div>
                            <p>{item.testimonialTitle}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }) : null;

    return details && details.testimonials && details.testimonials.length ? (
        <Section className={styles.testimonials} theme={theme}>
            <BackgroundImage image={imageData} gatsbyFile={true} />
            <Container>
                <Row>
                    <div className={styles.testimonials__content}>
                        <div className={styles.title}>
                            {details && <h6>{details.testimonialsTitle}</h6>}
                        </div>
                    </div>
                </Row>
            </Container>
            {details ?
                <div className={styles.sliderContainer}>
                    <Slider items={details.testimonials} itemElements={testimonialsElements} type="testimonials" />
                </div>
            : null }
            <Container>
                <Row>
                    <div className={styles.testimonials__content}>
                        <div className={styles.details}>
                            {details && details.featuredInTitle &&
                                <p className={`${styles.metaSub} meta-sub`}>{details.featuredInTitle}</p>
                            }
                            {publications &&
                                <div className={styles.publications}>
                                    {publications}
                                </div>
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default Testimonials;