import React, { useEffect, useRef } from "react"
import { useWebGLContext } from "../components/contexts/WebGLContext"

import SEO from "../components/seo"
import HeroMain from "../components/HeroMain"
import { graphql } from "gatsby"
import Testimonials from "../components/Testimonials"
import Introduction from "../components/Introduction"
import HowItWorks from "../components/HowItWorks"
import Cta from "../components/Cta"
import ThreeColumnInfo from "../components/ThreeColumnInfo/ThreeColumnInfo"
import OurProducts from "../components/OurProducts"
import BlogPosts from "../components/BlogPosts"
import Footer from "../components/Footer"

const IndexPage = props => {
    const howItWorksRef = useRef();

    const craftData = props.data.craft.entry
    const webglContext = useWebGLContext()

    const {
        homeHero,
        introduction,
        threeColumnInfoWithImages,
        ourProducts,
        testimonials,
        callToAction,
        howItWorks,
    } = craftData

    const heroData = homeHero ? homeHero[0] : {}
    const {displayTitle, description: heroDescription, primaryCta: heroPrimaryCta, secondaryCta: heroSecondaryCta} = heroData

    const testimonialsData = testimonials && testimonials.length ? testimonials[0] : null

    const introductionData = introduction && introduction.length ? introduction[0] : {}
    const {introductionTitle, introductionContent} = introductionData

    const threeColumnInfoData = threeColumnInfoWithImages && threeColumnInfoWithImages.length ? threeColumnInfoWithImages : null

    const howItWorksTiles =
        howItWorks && howItWorks.length && howItWorks[0] ? howItWorks[0].tiles : null

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {}
    const {ctaTitle: ctaText, primaryLink: ctaLink1, secondaryLink: ctaLink2} = ctaData

    useEffect(() => {
        const { data, setData } = webglContext
        const newData = Object.assign({}, data)
        newData.active = true

        setData({ ...newData })

        return () => {
            const { data, setData } = webglContext
            const newData = Object.assign({}, data)
            newData.active = false
            setData({ ...newData })
        }
    }, [])

    return (
        <>
            <SEO title="Home" seomatic={craftData.seomatic} />
            <HeroMain
                title={displayTitle}
                description={heroDescription}
                primaryCta={heroPrimaryCta}
                secondaryCta={heroSecondaryCta}
                animation={true}
                theme="dark"
                howItWorksRef={howItWorksRef}
            />
            <HowItWorks
                items={howItWorksTiles}
                animation={true}
                theme="dark"
                ref={howItWorksRef}
            />
            <Introduction
                title={introductionTitle}
                content={introductionContent}
                colorsAlt={true}
                shortPaddingBottom={true}
                theme="light"
            />
            <ThreeColumnInfo
                items={threeColumnInfoData}
                colorsAlt={true}
                centerMobile={true}
                theme="light"
            />
            <OurProducts items={ourProducts || null} theme="light" />
            <BlogPosts theme="light" />
            <Testimonials details={testimonialsData} theme="brightscan-image" />
            <Cta
                text={ctaText}
                link1={ctaLink1}
                link2={ctaLink2}
                theme="brightscan-dark"
            />
            <Footer theme="dark" />
        </>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query HomeQuery {
        craft {
            entry(section: "home") {
                seomatic(asArray: true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                ... on CRAFT_home_home_Entry {
                    homeHero {
                        ... on CRAFT_homeHero_BlockType {
                            displayTitle
                            description
                            primaryCta {
                                type
                                url
                                target
                                text
                            }
                            secondaryCta {
                                type
                                url
                                target
                                text
                            }
                        }
                    }
                    introduction {
                        ... on CRAFT_introduction_BlockType {
                            introductionTitle
                            introductionContent
                        }
                    }
                    threeColumnInfoWithImages {
                        ...on CRAFT_threeColumnInfoWithImages_BlockType {
                            columnTitle
                            columnImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(
                                    transform: "threeColImage"
                                ) {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(
                                    transform: "threeColImageWebp"
                                ) {
                                    width
                                    height
                                    url
                                }
                            }
                            columnContent
                        }
                    }
                    howItWorks {
                        ... on CRAFT_howItWorks_BlockType {
                            tiles {
                                ... on CRAFT_tiles_tile_BlockType {
                                    tileTitle
                                    tileContent
                                }
                            }
                        }
                    }
                    ourProducts {
                        ... on CRAFT_ourProducts_BlockType {
                            productTitle
                            logo {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(
                                    transform: "ourProductsLogoImage"
                                ) {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(
                                    transform: "ourProductsLogoImageWebp"
                                ) {
                                    width
                                    height
                                    url
                                }
                            }
                            productName
                            productContent
                            primaryCta {
                                type
                                url
                                target
                                text
                            }
                            secondaryCta {
                                type
                                url
                                target
                                text
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(
                                    transform: "ourProductsDesktopImage"
                                ) {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(
                                    transform: "ourProductsDesktopImageWebp"
                                ) {
                                    width
                                    height
                                    url
                                }
                            }
                            mobileImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(
                                    transform: "ourProductsMobileImage"
                                ) {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(
                                    transform: "ourProductsMobileImageWebp"
                                ) {
                                    width
                                    height
                                    url
                                }
                            }
                            backgroundImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(
                                    transform: "fullWidthBackgroundImage"
                                ) {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(
                                    transform: "fullWidthBackgroundImageWebp"
                                ) {
                                    width
                                    height
                                    url
                                }
                            }
                            style
                            features {
                                ... on CRAFT_features_feature_BlockType {
                                    feature
                                    icon {
                                        contents
                                    }
                                }
                            }
                            theme {
                                ... on CRAFT_themes_theme_Entry {
                                    color
                                }
                            }
                        }
                    }
                    testimonials {
                        ... on CRAFT_testimonials_BlockType {
                            testimonialsTitle
                            testimonials {
                                ... on CRAFT_testimonials_testimonial_BlockType {
                                    testimonialContent
                                    testimonialName
                                    testimonialTitle
                                    portrait {
                                        lqPlaceholder
                                        width
                                        height
                                        responsiveImage(
                                            transform: "blockQuoteImage"
                                        ) {
                                            width
                                            height
                                            url
                                        }
                                        responsiveImageWebp: responsiveImage(
                                            transform: "blockQuoteImageWebp"
                                        ) {
                                            width
                                            height
                                            url
                                        }
                                    }
                                }
                            }
                            featuredInTitle
                            publications {
                                ... on CRAFT_publications_publication_BlockType {
                                    logo {
                                        lqPlaceholder
                                        width
                                        height
                                        responsiveImage(
                                            transform: "publicationImage"
                                        ) {
                                            width
                                            height
                                            url
                                        }
                                        responsiveImageWebp: responsiveImage(
                                            transform: "publicationImageWebp"
                                        ) {
                                            width
                                            height
                                            url
                                        }
                                    }
                                    publicationName
                                }
                            }
                        }
                    }
                    callToAction {
                        ... on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                target
                                text
                            }
                            secondaryLink {
                                type
                                url
                                target
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`
